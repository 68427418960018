import { email, telephone, onlyNumberIČO } from "./patterns";
import {EMAIL_ERROR, TELEPHONE_ERROR, ZIP_ALLOWED_ERROR, IČO_ERROR} from "./error-messages";
import {ALLOWED_MANUALY_ZIPS} from "./homepage";

export const emailValidation = {
  value: email,
  message: EMAIL_ERROR
};

export const telephoneValidation = {
  value: telephone,
  message: TELEPHONE_ERROR
};



export const validateZip = (value) => {
  return ALLOWED_MANUALY_ZIPS.includes(value.replace(/\s/g, "")) || ZIP_ALLOWED_ERROR
}

export const IČO = (value) => {
  if(!onlyNumberIČO.test(value)){
    return IČO_ERROR
  }else {
    return undefined
  }
}
export const validateIČO = {
  value: onlyNumberIČO,
  message: IČO_ERROR
}