import React, { useEffect } from "react";
import formGroupClasses from './form-group.module.scss';
import classNames from 'classnames';

const FormGroup = function ({ children, className }) {


    return (
        <div className={classNames(formGroupClasses['formGroup'], className)}>
            {children}
        </div>
    )
};

export default FormGroup;
