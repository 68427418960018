import React from "react";
import classNames from "classnames";
import { string, bool, func, object } from "prop-types";
import { ErrorMessage } from "@hookform/error-message";
import { REQUIRED_ERROR } from "../../../constants/error-messages";

import styles from "./text-field.module.scss";

function FieldInput({
  label,
  name,
  register,
  optional,
  border,
  shadow,
  type,
  errors,
  pattern,
  defaultValue,
  validate,
  isOffAutocomplete,
  manageErrorPostGode,
  value,
}) {
  const inputStyles = classNames(styles.input, {
    [styles.border]: border,
    [styles.shadow]: shadow,
  });

  return (
    <>
      {label && (
        <label htmlFor={name}>
          <>
            {label}
            {optional && (
              <span className={styles.optionalLabel}>(Nepovinné)</span>
            )}
          </>
        </label>
      )}
      <input
        id={name}
        name={name}
        ref={register({
          required: !optional ? REQUIRED_ERROR : false,
          pattern,
          validate,
        })}
        type={type}
        className={inputStyles}
        defaultValue={defaultValue}
        autoComplete={isOffAutocomplete ? "off" : "on"}
        onChange={manageErrorPostGode ? manageErrorPostGode : null}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <span className={styles.errorText}>{message}</span>
          )}
        />
      )}
    </>
  );
}

FieldInput.defaultProps = {
  register: null,
  border: false,
  shadow: false,
  optional: false,
  type: "text",
  errors: {},
  pattern: {},
  defaultValue: "",
  validate: undefined,
  label: "",
};

FieldInput.propTypes = {
  register: func,
  name: string.isRequired,
  label: string,
  border: bool,
  shadow: bool,
  optional: bool,
  type: string,
  errors: object,
  pattern: object,
  defaultValue: string,
  validate: func,
};

export default FieldInput;
