const CITY_LABEL = "Mesto";
const STREET_LABEL = "Ulica a číslo";
 const POSTCODE_LABEL = "PSČ";
const STREET_INPUT_NAME = "street";
const CITY_INPUT_NAME = "city";
const POSTCODE_INPUT_NAME = "postcode";

const DELIVERY_INPUTS = [
  
  {
    name: "deliveryStreet",
    label: STREET_LABEL,
    defaultName: "street",
  },
  {
    name: "deliveryCity",
    label: CITY_LABEL,
    defaultName: "city",
  },
  {
    name: "deliveryZip",
    label: POSTCODE_LABEL,
    defaultName: POSTCODE_INPUT_NAME,
  },
];

const PICKUP_INPUTS = [
  {
    name: "pickupStreet",
    label: STREET_LABEL,
    defaultName: STREET_INPUT_NAME,
  },
  {
    name: "pickupCity",
    label: CITY_LABEL,
    defaultName: CITY_INPUT_NAME,
  },
  {
    name: "pickupZip",
    label: POSTCODE_LABEL,
    defaultName: POSTCODE_INPUT_NAME,
  },
];

const ADDRESS_INPUTS = [
  {
    label: STREET_LABEL,
    name: STREET_INPUT_NAME,
  },
  {
    label: CITY_LABEL,
    name: CITY_INPUT_NAME,
  },
  {
    label: POSTCODE_LABEL,
    name: POSTCODE_INPUT_NAME,
  },
];

export { DELIVERY_INPUTS, PICKUP_INPUTS, ADDRESS_INPUTS,  };