export const EMAIL_ERROR = "Nesprávny formát e-mailu";

export const REQUIRED_ERROR = "Tento údaj je povinný";

export const ZIP_ALLOWED_ERROR = 'Toto PSČ nevozime';

export const TELEPHONE_ERROR = "Nesprávny formát";

export const PAYMENT_TYPE_ERROR = "Vyberte spôsob platby"

export const TERMS_ERROR = "Prosím nezabudnite súhlasiť s obchodnými podmienkami"

export const ANY_ERROR = "Formulár obsahuje chyby, ktoré je potrebné opraviť"

export const IČO_ERROR = "Akceptuje len čísla"
export const NOT_CHOOSEN_TIME_PICKUP = 'Prosím zvoľte dátum'
export const NOT_SENT_ORDER_BY_EMAIL='Server for sending mail is temporarily unavailable please try again later' 
export const NOT_CHOOSEN_PICKUP_ADDRESS ='Prosím zvoľte adresu vyzdvihnutia'
export const NOT_CHOOSE_DELIVERY_ADDRESS ='Prosím zvoľte adresu doručenia'