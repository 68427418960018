import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import modalWindowClasses from "./modal-window.module.scss";
import CloseIcon from "src/components/close-icon";

const ModalWindow = function ({
  title,
  body,
  isOpen = false,
  onToggle = () => {},
  onClose = () => {},
  onOpen = () => {},
}) {
  useEffect(() => {
    if (setIsOpen) {
      document.body.classList.remove("hidden");
    }
    setIsOpen(isOpen);
  }, [isOpen]);

  
  const [modalIsOpen, setIsOpen] = useState(isOpen);

  function afterOpenModal() {
      document.body.classList.add("hidden");
  }

  function closeModal() {
    onToggle(false);
    onClose();
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
      overlayClassName={modalWindowClasses["modalWindowOverlay"]}
      className={modalWindowClasses["modalWindow"]}
    >
      <div className={modalWindowClasses.modalWrap}>
        <div className={modalWindowClasses["header"]}>
          <h3>{title}</h3>
          <CloseIcon
            className={modalWindowClasses["closeButton"]}
            onClick={closeModal}
          />
        </div>
        <div className={modalWindowClasses["body"]}>{body}</div>
      </div>
    </Modal>
  );
};

export default ModalWindow;
