import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormGroup from "../form-group";
import { useForm, useWatch } from "react-hook-form";
import FieldInput from "src/components/forms/text-field/text-field";
import Button from "../button";
import { ADDRESS_INPUTS } from "../../constants/forms/delivery-address";
import styles from "./manula-address-handle-form.module.scss";

function ManualAddressHandleForm({
  defaultValues,
  presetAddress,
  onSubmit,
  isErrorZipCodeValidate,
  lastManualAddress,
  setIsErrorZipCodeValidate,
}) {
  const { register, errors, control, handleSubmit, setValue } = useForm({
    defaultValues,
  });
  const [lastOneManualAddress, setLastManualAddress] = useState(
    lastManualAddress ? lastManualAddress() : null
  );

  useEffect(() => {
    if (lastOneManualAddress) {
      for (let key in lastOneManualAddress) {
        setValue(key, lastOneManualAddress[key]);
      }
    } else if (presetAddress) {
      for (let key in presetAddress.geoCode) {
        setValue(key, presetAddress.geoCode[key]);
      }
    }
  }, [lastOneManualAddress, presetAddress]);

  const manageErrorPostGode = () => {
    setIsErrorZipCodeValidate("");
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {ADDRESS_INPUTS.map((input, index) => {
        return (
          <FormGroup className={styles.formGroup} key={index}>
            <FieldInput
              require
              register={register}
              name={input.name}
              label={input.label}
              errors={errors}
              shadow
              manageErrorPostGode={manageErrorPostGode}
            />
          </FormGroup>
        );
      })}
      <div className={styles.buttonSection}>
        {isErrorZipCodeValidate ? (
          <p className={styles.errorInfo}>{isErrorZipCodeValidate}</p>
        ) : (
          ""
        )}
        <Button className={styles.submitButton} color="green" type="submit">
          Uložiť
        </Button>
      </div>
    </form>
  );
}

ManualAddressHandleForm.defaultProps = {
  defaultValues: {
    description: "",
    geoCode: {
      street: "",
      postcode: "",
      city: "",
    },
  },
  onSubmit: () => {},
};

ManualAddressHandleForm.propTypes = {
  defaultValues: PropTypes.shape({
    description: PropTypes.string,
    geoCode: PropTypes.object,
  }),
  onSubmit: PropTypes.func,
};

export default ManualAddressHandleForm;
